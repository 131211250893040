export const tracks = [
  'audio/lofi/Chill-RnB-Dreaming.mp3',
  'audio/lofi/Lo-Fi-Chill.mp3',
  'audio/lofi/Lo-Fi-Chillout-Travel-Ambient.mp3',
  'audio/lofi/Lo-Fi-Hip-Hop-Groovy-Dreaming.mp3',
  'audio/lofi/Lo-Fi-Travel-Neo-Soul.mp3',
];

export let SOUNDS = [
  {
    title: 'Birds',
    name: 'birds',
  },
  {
    title: 'Night Birds',
    name: 'comfort',
  },
  {
    title: 'Cat',
    name: 'cat',
  },
  {
    title: 'Bonfire',
    name: 'bonfire',
  },
  {
    title: 'Fireplace',
    name: 'fireplace',
  },
  {
    title: 'Forest Night',
    name: 'forest_night',
  },
  {
    title: 'Keyboard',
    name: 'keyboard',
  },
  {
    title: 'City Traffic',
    name: 'city_traffic',
  },

  {
    title: 'People Talk',
    name: 'people_talk_inside',
  },
  {
    title: 'Rain',
    name: 'rain',
  },
  {
    title: 'Thunders',
    name: 'thunders',
  },
  {
    title: 'Wind',
    name: 'wind',
  },
  {
    title: 'River',
    name: 'river',
  },
  {
    title: 'Sea',
    name: 'sea',
  },
  {
    title: 'Ocean',
    name: 'ocean',
  },
  {
    title: 'Waves',
    name: 'waves',
  },
  {
    title: 'Underwater',
    name: 'underwater',
  },
  {
    title: 'Train',
    name: 'train',
  },
  {
    title: 'Airplane',
    name: 'airplane',
  },
  {
    title: 'Vinyl',
    name: 'vinyl',
  },
  {
    title: 'Deepspace',
    name: 'deepspace',
  },
  {
    title: 'White Noise',
    name: 'white-noise',
  },

  {
    title: 'Brown Noise',
    name: 'brown-noise',
  },
  {
    title: 'Pink Noise',
    name: 'pink-noise',
  },
];